@import "../typography.scss";
@import "../mixins.scss";

.authorizePage {
  .alexaLogoContainer {
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  .containerForLinks {
    justify-content: flex-end;
  }
}
