$backgroundColor: #ffffff;
$backgroundLoggedColor: #f1f4f9;
$textColor: #3c3c3c;
$linkColor: #31508c;
$actionColor: #0091ea;
$dangerBgColor: #ffb9b9;
$dangerColor: #e40d0d;
$successColor: #52c41a;
$disabledBgColor: #e0e0e0;
$lightTextColor: #ffffff;
$offlineBgColor: #5c90d2;
$offlineFgColor: #ffffff;

$headerTextColor: $linkColor;
$popOverColor: $linkColor;
$separatorColor: darken($backgroundLoggedColor, 12%);
$formFieldBgColor: $backgroundLoggedColor;
$backgroundColorAltRows: lighten($backgroundLoggedColor, 1.5%);

@mixin customHeaderStyles() {
}

@mixin customAppStyles() {
  background: url("/assets/footer-background.png") bottom no-repeat;
  background-size: auto;

  @include responsive-min($size-xxl) {
    background-size: contain;
  }
}
